<template>
  <div class="step1">
    <!-- <PlateTitle title="基本户信息" />
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-item
        label="企业基本户名称"
        has-feedback
      >
        <span>{{ dataSource.basicAccountName }}</span>
      </a-form-item>
      <a-form-item
        label="企业基本户账号"
        has-feedback
      >
        <span>{{ dataSource.basicAccountNo }}</span>
        <a
          v-if="step === 0"
          @click="goDoor"
        >&nbsp;&nbsp;&nbsp;&nbsp;修改信息</a>
      </a-form-item>
    </a-form> -->
    <PlateTitle title="投保信息" />
    <a-spin :spinning="spinning">
      <a-form
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 8 }"
      >
        <a-form-item
          label="申请人"
          has-feedback
        >
          <span>{{ dataSource.name }}</span>
        </a-form-item>
        <a-form-item
          label="申请人统一社会信用代码"
          has-feedback
        >
          <span>{{ dataSource.creditCode }}</span>
        </a-form-item>
        <!-- <a-form-item
        label="投保人手机号"
        has-feedback
      >
        <span>{{ dataSource.mobile }}</span>
      </a-form-item> -->
      </a-form>
      <a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 8 }"
      >
        <!-- <a-form-item
          label="经办人姓名"
          has-feedback
        >
          <a-input
            v-decorator="['name', {rules: [{validator: compareToFirstName},],}]"
            placeholder="请输入经办人姓名"
            style="width: 280px"
            :disabled="step === 1"
          />
        </a-form-item> -->
        <a-form-item
          label="经办人手机号"
          has-feedback
        >
          <a-input
            v-decorator="['phoneNum', {rules: [{validator: compareToFirstPhone},],}]"
            placeholder="请输入经办人手机号"
            style="width: 280px"
            v-if="step === 0"
          />
          <span v-if="step === 1">{{ dataSource.mobile }}</span>
        </a-form-item>
        <span v-if="step === 1">
          <a-form-item
            label="开立人"
            has-feedback
          >
            <span>{{ dataSource.openPeople }}</span>
          </a-form-item>
          <a-form-item
            label="担保金额"
            has-feedback
          >
            <span>{{ dataSource.sumAmount }}元</span>
          </a-form-item>
          <a-form-item
            label="担保费"
            has-feedback
          >
            <span>{{ dataSource.sumPremium }}元</span>
          </a-form-item>
          <a-form-item
            label="费率"
            has-feedback
          >
            <span>{{ dataSource.rate }}%</span>
          </a-form-item>
        </span>
        <!-- <a-form-item
          label="验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['code', {rules: [{validator: compareToFirstCode},],}]"
            placeholder="请输入验证码"
            style="width: 280px"
          />
          <img
            :src="codeImg"
            alt=""
            @click="code"
          >
        </a-form-item>
        <a-form-item
          label="短信验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['phCode', {rules: [{validator: compareToFirstPhcode}],}]"
            placeholder="请输入短信验证码"
            style="width: 280px"
          />
          <a
            v-if="isTime"
            @click="phoneCode"
          >获取验证码</a>
          <span v-else>{{ time }}s重新获取</span>
        </a-form-item> -->
        <a-form-item
          v-if="step === 0"
        >
          <a-checkbox
            v-decorator="['checked', {rules: [{validator: compareToFirstChecked},],}]"
            style="width: 500px;"
          >
            已阅读并同意<a
              class="hrefa"
              :href="'/api/insure/index/download?type=apply&orderNo='+orderNo"
              @click="download(1)"
            >
            <!-- 《投标保函申请书》 -->
            </a><a
              class="hrefa"
              :href="'/api/insure/index/download?type=rules&orderNo='+orderNo"
              @click="download(2)"
            >《投标保函开立、退费、代偿规则》</a><a
              class="hrefa"
              :href="'/api/insure/index/download?type=introduction&orderNo='+orderNo"
              @click="download(3)"
            >《投标保证金电子保函业务介绍》</a>
          </a-checkbox>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import { code, phoneCode } from '@/api/insure.js';
const querystring = require('querystring');
export default {
  name: 'Step1',
  components: {
    PlateTitle,
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
    spinning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uuid: '',
      codeImg: '',
      isTime: true,
      time: 60,
      form: this.$form.createForm(this, { name: 'loginForm' }),
      orderNo: '',
      download1: false,
      download2: false,
      download3: false,
    };
  },
  mounted() {
    this.orderNo = querystring.parse(window.location.search)['?orderNo'];
    this.code();
  },
  methods: {
    goDoor() {
      this.$router.push({ name: 'BasicDoor', query: { id: this.dataSource.id } });
    },
    /* 图片验证码 */
    code() {
      code().then(res => {
        this.codeImg = res.img;
        this.uuid = res.uuid;
      });
    },
    /* 获取验证码 */
    phoneCode() {
      this.timer = '';
      if (this.form.instances.phoneNum.stateValue !== '' && this.form.instances.code.stateValue !== '') {
        phoneCode({ codeKey: this.uuid, code: this.form.instances.code.stateValue, phoneNum: this.form.instances.phoneNum.stateValue }).then(res => {
          if (res === '') {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('手机号码和图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 60;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    download(type) {
      if (type === 1) {
        this.download1 = true;
      } else if (type === 2) {
        this.download2 = true;
      } else if (type === 3) {
        this.download3 = true;
      }
    },

    // 自定义校验
    compareToFirstName(rule, value, callback) {
      if (value === '' || !value) {
        callback('请输入经办人姓名');
      } else {
        callback();
      }
    },
    compareToFirstPhone(rule, value, callback) {
      const pattern = /^1[3|4|5|7|8][0-9]{9}$/;
      if (pattern.test(value) && value) {
        callback();
      } else {
        callback('请输入正确的手机号');
      }
    },
    compareToFirstCode(rule, value, callback) {
      if (value === '' || !value) {
        callback('请输入图形验证码');
      } else {
        callback();
      }
    },
    compareToFirstPhcode(rule, value, callback) {
      if (value === '' || !value) {
        callback('请输入短信验证码');
      } else {
        callback();
      }
    },
    compareToFirstChecked(rule, value, callback) {
      if (!value) {
        callback('请勾选协议');
      } else {
        callback();
      }
      console.log(this.download1, this.download2, this.download3);
      // if (this.download1 && this.download2 && this.download3) {
      //   if (!value) {
      //     callback('请勾选协议');
      //   } else {
      //     callback();
      //   }
      // } else {
      //   this.$message.warning('请下载并阅读所有协议');
      // }
    },
  },
};
</script>

<style lang="less" scoped>

.step1 {
  /deep/ .ant-row {
    display: flex;
    justify-content: center;
  }
  .hrefa {
    color: #666
  }
  .code {
    img {
      position: absolute;
      cursor: pointer;
      left: 185px;
      top: -5px;
      height: 30px;
    }
    a, span {
      position: absolute;
      width: 80px;
      left: 200px;
    }
  }
}
</style>
