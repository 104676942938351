<template>
  <div class="container">
    <Progress
      :active-index="step"
      :step-list="stepList"
    />
    <div class="main">
      <!-- <a-spin :spinning="spinning" /> -->
      <Step2
        v-if="step === 2"
      />
      <Step1
        ref="step1"
        v-if="step === 0 || step === 1"
        :step="step"
        :data-source="dataSource"
        :spinning="spinning"
      />
      <Operate
        class="operate"
        :step="step"
        :is-next="isNext"
      />
    </div>
    <DocumentDialog
      class="insureInfo"
      title="保函开立须知"
      :content="invitationLetterStr"
      ok-text="我已阅读开立须知并同意"
      cancel-text="不同意，取消开立"
      :is-show-know-tips="false"
      :visible="insureInfoDialog.visible"
      @ok="()=>{insureInfoDialog.visible=false;openPage()}"
      @cancel="()=>{insureInfoDialog.visible=false;closePage()}"
      v-if="step !== 2"
    />
  </div>
</template>

<script>
import handlebars from 'handlebars';
import invitationLetterTpl from '@/assets/tpl/invitationLetter.js';
import DocumentDialog from '@/components/DocumentDialog';
import Progress from './../../components/Progress';
import Operate from './components/Operate';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
// import PickCompany from './components/PickCompany';
import { information, getInformation, verify, applyInsureMoneyVerify } from '@/api/insure.js';
const querystring = require('querystring');

export default {
  name: 'InsurePage',
  components: {
    Progress,
    Operate,
    DocumentDialog,
    Step1,
    Step2,
  },
  data() {
    return {
      spinning: false,
      orderNo: '',
      status: '',
      step: 2,
      dataSource: {},
      invitationLetterTpl,
      stepList: [ '填写投保信息', '确认投保信息', '提交投保完成' ],
      pickCompanyCode: '',
      insureInfoDialog: {
        visible: true,
      },
      isNext: false,
    };
  },
  mounted() {
    this.getInformation();
  },
  methods: {
    getInformation() {
      this.orderNo = querystring.parse(window.location.search)['?orderNo'];
      getInformation({ orderNo: this.orderNo }).then(res => {
        this.status = res.status;
        this.dataSource = res;
        // 暂时隐藏
        if (res.status === '9' || !res.mobile) {
          this.step = 0;
          if (res.mobile) {
            this.step = 1;
          }
        } else {
          this.step = 2;
        }
        // applyInsureMoneyVerify({ orderId: this.orderNo }).then(res => {
        //   if (res.status === 500 && (this.step === 0 || this.step === 1)) {
        //     console.log('qwe');
        //     this.$message.error(res.message);
        //     this.isNext = true;
        //   }
        // });
      });


    },
    // 下一步
    information() {
      if (this.step === 0) {
        this.$refs.step1.form.validateFields((err, values) => {
          if (!err) {
            console.log(values);
            // if (!values.checked) {
            //   this.$message.warning('请勾选协议');
            //   return false;
            // }
            this.spinning = true;
            const data = {
              orderNo: this.orderNo,
              responsiblePersonPhone: values.phoneNum,
              // phoneCode: values.phCode,
              // responsiblePersonName: values.name,
            };
            information(data).then(res => {
              console.log(res);
              if (res.status === 200) {
                this.step = 1;
                this.dataSource.mobile = values.phoneNum;
              } else {
                this.$notification.error({
                  message: res.message,
                });
              }
              this.spinning = false;
            });
          }
        });
      } else if (this.step === 1) {
        this.spinning = true;
        verify({ orderNo: this.orderNo }).then(res => {
          console.log(res);
          if (res.status === 200) {
            this.step = 2;
          } else {
            this.$notification.error({
              message: res.message,
            });
          }
          this.spinning = false;
        });
      }
    },
    back() {
      this.step -= 1;
    },
    handlePickCompany(code) {
      this.pickCompanyCode = code;
    },
    openPage() {
      applyInsureMoneyVerify({ orderId: this.orderNo }).then(res => {
        if (res.status === 500) {
          console.log('qwe');
          this.$message.error(res.message);
          this.isNext = true;
        }
      });
    },
    closePage() {
      if (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) {
        window.location.href = 'about:blank';
        window.close();
      } else {
        window.opener = null;
        window.open(' ', '_self');
        window.close();
      }
    },
  },
  computed: {
    invitationLetterStr() {
      const getStr = handlebars.compile(invitationLetterTpl);
      return getStr({
        toCompanyName: 'toCompanyName',
        fromCompanyName: 'fromCompanyName',
        fromCompanyCode: 'fromCompanyCode',
        fromCompanyAddress: 'fromCompanyAddress',
        insuranceAmount: 'insuranceAmount',
        insuranceAmountInWords: 'insuranceAmountInWords',
        securityAmount: 'securityAmount',
        securityAmountInWords: 'securityAmountInWords',
        rate: this.dataSource.rate,
        minSumPremium: this.dataSource.minSumPremium,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container{
  background-color: #fff;
  min-height: 726px;
}
.main{
  padding: 30px 50px 95px;
}
.pickCompany{
  margin-top:90px;
}
.operate{
  margin-top:80px;
}
</style>
